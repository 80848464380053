import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import './assets/css/main.scss';
import './assets/fonts/fonts.css';

// eslint-disable-next-line import/no-extraneous-dependencies,import/order
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

createApp(App).component('font-awesome-icon', FontAwesomeIcon).use(router).mount('#app');
