
import {defineComponent} from 'vue';
import DonationBox from "@/components/DonationTierBox.vue";
import ButtonSquare from "@/components/ButtonSquare.vue";
import FieldTripHero from "@/components/heros/FieldTripHero.vue";

export default defineComponent({
  name: 'JoinView',
  components: {FieldTripHero, ButtonSquare, DonationBox},
  data() {
    return {
      showMissionOne: true,
      showMissionTwo: true,
      showMissionThree: true,
      showMissionFour: true,
      museumPartners: [
        {image: 'ETHSLogo.png', link: 'http://www.easttnhistory.org/'},
        {image: 'LMULogo.png', link: 'https://www.lmunet.edu/'},
        {image: 'McCLungMuseum.png', link: 'https://mcclungmuseum.utk.edu/'},
        {image: 'kmalogo.png', link: 'https://knoxart.org/'},
        {image: 'BCM_Logo_dark.png', link: 'https://birthplaceofcountrymusic.org/'},
      ],
      theaterPartners: [
        {image: 'BIJOU.png', link: 'https://knoxbijou.org/'},
        {image: 'THEPOINT.jpg', link: 'https://thepointknox.com/'},
        {image: 'Pellissippi_State.png', link: 'https://thepointknox.com/'},
        {
          image: 'LMU_ARENA.png',
          link: 'https://lmurailsplitters.com/facilities/b-frank-tex-turner-arena/1'
        },
      ]
    }
  },
  methods: {
    openLink(link: string) {
      if (link) {
        window.open(link, '_blank')
      }
    }
  }
});
