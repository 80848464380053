
import {defineComponent} from 'vue'

export default defineComponent({
  name: "PartnersHome",
  data() {
    return {
      viewMore: false,
      partnerImages: [
        {image: 'BoysAndGirlsClubsLogo.png', link: 'https://www.bgca.org/'},
        {image: 'ETHSLogo.png', link: 'http://www.easttnhistory.org/'},
        {image: 'ElginFoundationLogo.png', link: 'http://elginfoundation.org/'},
        {image: 'JAM.svg', link: 'https://jamkids.org/'},
        {image: 'PSTCCLogo.png', link: 'https://www.pstcc.edu/'},
        {image: 'RoaneStateCollege.svg', link: 'https://www.roanestate.edu/'},
        {image: 'BBBSLogo.png', link: 'https://tennesseebig.org/'},
        {image: 'Dolly_Imagination_Library_Logo.png', link: 'https://imaginationlibrary.com/'},
        {image: 'LMULogo.png', link: 'https://www.lmunet.edu/'},
        {image: 'MissionofHopeLogo.png', link: 'https://missionofhope.org/'},
        {image: 'ThriveLogo.png', link: 'https://www.helpusthrive.com/'},
        {image: 'Second_Harvest_Logo.png', link: 'https://secondharvestetn.org/'},
        {image: 'TNArtsCommissionLogo.png', link: 'https://tnartscommission.org/'},
        {image: 'TN4ARTSLogo.png', link: 'https://www.tn4arts.org/'},
        {image: 'WATECh6Logo.png', link: 'https://www.wate.com/'}
      ]
    }
  },
  computed: {
    partners(): { image: string, link: string }[] {
      return this.viewMore ? this.partnerImages : this.partnerImages.slice(0, 5)
    }
  },
  methods: {
    openLink(link: string) {
      if (link) {
        window.open(link, '_blank')
      }
    }
  }
})
