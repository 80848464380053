
import {defineComponent} from 'vue'

export default defineComponent({
  name: "ScheduleHero",
  data() {
    return {
      scrollY: 0,
      dates: [9, 10, 11, 16, 17, 18, 23, 24]
    }
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll)
  },
  methods: {
    onScroll(e: any) {
      if (window.scrollY >= 540) return

      this.scrollY = window.scrollY
    }
  }
})
