import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScheduleHero = _resolveComponent("ScheduleHero")!
  const _component_MissionHome = _resolveComponent("MissionHome")!
  const _component_ImpactHome = _resolveComponent("ImpactHome")!
  const _component_JoinHome = _resolveComponent("JoinHome")!
  const _component_GetInvolvedHome = _resolveComponent("GetInvolvedHome")!
  const _component_SponsorHome = _resolveComponent("SponsorHome")!
  const _component_PartnersHome = _resolveComponent("PartnersHome")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ScheduleHero),
    _createVNode(_component_MissionHome),
    _createVNode(_component_ImpactHome),
    _createVNode(_component_JoinHome),
    _createVNode(_component_GetInvolvedHome),
    _createVNode(_component_SponsorHome),
    _createVNode(_component_PartnersHome)
  ], 64))
}