
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'FieldTripHero',
  props: {
    topPadding: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      dates: [
        {month: 'SEPT', day: '9'},
        {month: 'SEPT', day: '10'},
        {month: 'SEPT', day: '11'},
        {month: 'SEPT', day: '16'},
        {month: 'SEPT', day: '17'},
        {month: 'SEPT', day: '18'},
        {month: 'SEPT', day: '23'},
        {month: 'SEPT', day: '24'},
      ],
    }
  },
  methods: {
    openPdf() {
      window.open('https://docs.google.com/forms/d/e/1FAIpQLSfh825JCxxTWDgBGCUGrLLhqErJmcoQFou67o5Y3VLukL7xKQ/viewform', '_blank')
    }
  }
});
