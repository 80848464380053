
import {defineComponent} from 'vue'
import {NButton} from "naive-ui";
import ButtonSquare from "@/components/ButtonSquare.vue";
import PartnersView from "@/views/PartnersView.vue";

export default defineComponent({
  name: "MissionHome",
  components: {PartnersView, NButton, ButtonSquare},
  data() {
    return {
      showMissionOne: true,
      showMissionTwo: true,
      showMissionThree: true,
    }
  }
})
