
import {defineComponent} from 'vue';
import MissionHome from "@/components/MissionHome.vue";
import ImpactHome from "@/components/ImpactHome.vue";
import JoinHome from "@/components/JoinHome.vue";
import GetInvolvedHome from "@/components/GetInvolvedHome.vue";
import SponsorHome from "@/components/SponsorHome.vue";
import PartnersHome from "@/components/PartnersHome.vue";
import FieldTripHero from "@/components/heros/FieldTripHero.vue";
import ScheduleHero from "@/components/heros/ScheduleHero.vue";

export default defineComponent({
  name: 'HomeView',
  components: {
    ScheduleHero,
    FieldTripHero,
    PartnersHome, SponsorHome, GetInvolvedHome, JoinHome, MissionHome, ImpactHome
  },
});
